const genActionTypes = {
  SET_LOADING: "SET_LOADING",
  SET_CONNECTOR: "SET_CONNECTOR",
  SET_PROVIDER: "SET_PROVIDER",
  SET_CHAIN_ID: "SET_CHAIN_ID",
  SET_PROPOSED_CHAIN: "SET_PROPOSED_CHAIN",
  SET_ACCOUNT: "SET_ACCOUNT",
  SET_NOTIFICATION: "SET_NOTIFICATION",
  SET_CLIPBOARD: "SET_CLIPBOARD",
  SET_LOADER: "SET_LOADER",
  SET_MAINNET: "SET_MAINNET",
  TOGGLE_WALLET_POPUP: "TOGGLE_WALLET_POPUP",
  SET_SWITCH_WALLET_NOTIFICATION: "SET_SWITCH_WALLET_NOTIFICATION",
  SET_WEB3AUTH: "SET_WEB3AUTH",
  SET_NFTS: "SET_NFTS",
};

export default genActionTypes;
